import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  FormGroup,
  FormControlLabel,
  // Switch,
  Checkbox
} from '@mui/material';

import { useTheme } from '@mui/system';
import config from '@configFile';
import { useSignUpModal } from '@hooks/useLoginModal';
import { useGetUserData } from '@hooks/useGetUserData';
import trackUse from '@utils/trackUse';
import MonetizingLink from '@components/MonetizingLink';
import { getCouponLinkFromCode, logPostHogEvent } from '@utils/index';
import { useMarkCouponAsRead } from '../CouponButton/useMarkCouponRead';
import DealHasCoupon from '../DealHasCoupon';

const IsCouponUsed = ({
  promoCode,
  ASIN,
  tag = config.AFFILIATE_TAGS.PRODUCT_PAGE,
  showCouponMessage = true,
  shouldClaimedCoupon = true,
  couponMessageInitialOpen = false
}: {
  ASIN: string;
  promoCode: string;
  tag?: string;
  showCouponMessage?: boolean;
  shouldClaimedCoupon?: boolean;
  couponMessageInitialOpen?: boolean;
}) => {
  const { data: user } = useGetUserData();
  const { showNonLoggedInModal } = useSignUpModal();
  const { mutate: toggleMarkCouponAsRead } = useMarkCouponAsRead();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const hasUsedCouponAlready =
    promoCode &&
    !!(user?.allUsedCoupons || []).some(
      (usedCoupon) => usedCoupon.couponId === promoCode
    );

  const [hasUsedCoupon, setHasUsedCoupon] = useState(hasUsedCouponAlready);

  useEffect(() => {
    if (user?.allUsedCoupons) {
      const hasUsedCouponAlreadyUpdate =
        (user?.allUsedCoupons || []).length > 0 &&
        user?.allUsedCoupons.some((usedCoupon) => {
          return usedCoupon.couponId === promoCode;
        });

      if (hasUsedCouponAlreadyUpdate) {
        setHasUsedCoupon(true);
      } else {
        setHasUsedCoupon(false);
      }
    }
  }, [user, setHasUsedCoupon]);

  const couponSwitchAction = () => {
    toggleMarkCouponAsRead({
      ASIN,
      promoCode,
      newState: !hasUsedCoupon
    });
    trackUse({
      item: 'mark-coupon-used',
      value: promoCode,
      type: 'CLICK'
    });

    logPostHogEvent('mark-coupon-used', {
      value: promoCode,
      type: 'CLICK'
    });

    setHasUsedCoupon(!hasUsedCoupon);
  };

  const renderSwitch = () => {
    return (
      <FormGroup
        sx={{
          padding: '0',
          margin:
            showCouponMessage || (hasUsedCoupon && shouldClaimedCoupon)
              ? '8px 0px 0px 0px'
              : '0px',
          border: `1px solid ${isDarkMode ? '#bda4f4' : '#392e50'}`,
          borderRadius: '4px',
          fontWeight: 700,
          '@media (max-width: 600px)': {
            padding: '0px',
            margin: '12px 0px 0px 0px'
          }
        }}
      >
        <FormControlLabel
          sx={{
            margin: '0 10px',
            '@media (max-width: 540px)': {
              padding: '4px'
            }
          }}
          componentsProps={{
            typography: {
              sx: {
                fontSize: '13px !important',
                marginLeft: '8px !important',
                marginRight: '12px !important',
                fontWeight: 600,
                '@media (max-width: 540px)': {
                  fontSize: '0.8rem !important'
                }
              }
            }
          }}
          control={
            <Checkbox
              size="small"
              name={`switch-${promoCode}-${ASIN}`}
              checked={hasUsedCoupon}
              onChange={() => {
                if (!user) {
                  showNonLoggedInModal({
                    onLoginCBFn: () => {
                      couponSwitchAction();
                    },
                    modalMessage: 'Please login to mark coupon as used',
                    featureName: 'mark-coupon-used'
                  });
                } else {
                  couponSwitchAction();
                }
              }}
            />
          }
          label="Coupon used"
        />
      </FormGroup>
    );
  };

  const renderDealHasCoupon = () => {
    return (
      <Box data-testid="deal-has-coupon">
        {showCouponMessage ? (
          <DealHasCoupon
            promoCode={promoCode}
            tag={tag}
            marginBottom="12px"
            marginTop="0px"
            initialOpen={couponMessageInitialOpen}
          />
        ) : null}
        {renderSwitch()}
      </Box>
    );
  };

  if (!showCouponMessage && !shouldClaimedCoupon) {
    return <Box>{renderSwitch()}</Box>;
  }

  return (
    <>
      {hasUsedCoupon ? (
        <Box>
          {shouldClaimedCoupon ? (
            <Alert severity="warning" icon={false}>
              You&apos;ve marked this coupon as used so you may not be able to
              claim this deal again.{' '}
              <MonetizingLink
                href={getCouponLinkFromCode(promoCode, tag)}
                target="_blank"
                tag={tag}
                clickType="check-eligibility"
                underline="always"
              >
                Check your eligibility here.
              </MonetizingLink>
            </Alert>
          ) : null}
          {renderSwitch()}
        </Box>
      ) : (
        renderDealHasCoupon()
      )}
    </>
  );
};

export default IsCouponUsed;
